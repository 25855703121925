.wrapp-side-bar {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 345px;
    min-width: 345px;
    background: #FAFAFA;
    padding: 24px 32px;
    height: auto;
    text-align: left;
}

.info-text {
    font-family: 'Neue montreal';
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    color: #757575;
}

.info-text-strong {
    font-family: 'Neue montreal';
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    color: #757575;
}

.info-text-strong {
    font-family: 'Neue montreal';
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    color: #757575;
}

.head-card-sidebar {
    font-family: 'Neue montreal';
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    color: #1C83E3;
}

.card-side-bar {
    margin-top: 8px;
    background: #fff;
    padding: 16px 20px;
    border-radius: 21px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.text-head-card {
    font-family: 'Neue montreal';
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    color: #3C4549;
    letter-spacing: 2%;
}

.info-text-ligth {
    font-family: 'Neue montreal';
    font-size: 14px;
    font-weight: 400;
    line-height: 19.2px;
    color: #9E9E9E;
}

.wrapp-text-head-side-bar {
    display: flex;
    flex-direction: column;
    gap: 8px;
}