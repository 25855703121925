.card-upload {
    background: #fff;
    display: flex;
    flex-direction: column;
    /*display: grid;*/
    /*grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));*/
    gap: 0px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    padding: 16px 24px;
    border-radius: 12px;
    width: auto;
}

.wrapp-component-upload {
    width: 187px;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-upload-wrapper .ant-upload-drag .ant-upload {
    padding: 0px!important;
    overflow: hidden;
}

.wrapp-head-text-component-uploiad {
    display: flex;
    flex-direction: column;
    grid-area: 4px;
    margin-bottom: 16px;
}

.avatar-uploader {
    margin-bottom: 0px!important;
    height: 100%;
}

.avatar-uploader button {
    cursor: pointer;
    height: 100%!important;
}

.wrapp-upload-form-item {
    height: 100%;
    margin-bottom: 0px;
}

.wrapp-upload-form-item .ant-form-item-control-input {
    height: 136px;
}

.ant-form-item-control-input {
    height: auto;
}

.ant-form-item-control-input-content {
    height: 100%;
}

.ant-form-item-row {
    height: 100%;
}

.text-in-btn-upload {
    font-family: 'Neue montreal';
    font-size: 14px;
    font-weight: 400;
    line-height: 19.2px;
    color: #000000;
}

.text-in-btn-upload span {
    font-family: 'Neue montreal';
    font-size: 14px;
    font-weight: 400;
    line-height: 19.2px;
    color: #436FF1;
}

.wrapp-img-example-car-upload {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapp-img-example-car-upload img {
    width: 100%;
}

:where(.css-zd81jh).ant-upload-wrapper .ant-upload-drag .ant-upload {
    padding: 0px!important;
}